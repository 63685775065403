<template>
  <div>
    <div class="mx-5">
      <div @click="open = !open" class="d-flex flex-row align-center justify-space-between py-4"
      v-if="expanded">
        <p class="mb-0 title">{{ title }}</p>
        <v-icon v-if="!open" color="blue">mdi-chevron-down</v-icon>
        <v-icon v-else color="blue">mdi-chevron-up</v-icon>
      </div>
      <div v-if="open">
        <p v-for="i in items" :key="i.title" class="pl-2 py-2 subtitle" @click="$router.push(i.path)">{{ i.title }}</p>
      </div>
    </div>
    <div v-if="!expanded">
      <p @click="$router.push(path)" class="mb-0 mt-4 title mx-5">{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandItem',
  data() {
    return {
      open: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.title {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
}

.subtitle {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  color: $secondary-darkBlue !important;
}
</style>
