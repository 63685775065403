<template>
  <div>
    <v-menu offset-y v-if="isSelect" rounded="lg" class="container-menu-navbar">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-column align-center container-title">
          <div class="d-flex flex-row  pointer-path align-start" v-bind="attrs" v-on="on">
            <div class="d-flex flex-column">
              <p class="mb-0 pointer-path">
                {{ title }}
              </p>
              <div
                v-if="items.some(e => e.path === pathSelect)"
                :class="`d-flex flex-row mt-2 align-center justify-center marginPoints`"
              >
                <div v-for="i in pointsColor" :key="i.class" :class="`pointSize ${i.class}`"></div>
              </div>
            </div>
            <v-icon class="mx-4" color="black">mdi-chevron-down</v-icon>
          </div>
        </div>
      </template>
      <v-list class="container-item-list">
        <v-list-item v-for="(item, index) in items" :key="index" class="text-center">
          <v-list-item-title
            @click="
              () => {
                handleRouter(item.path, item.tag, item.title);
              }
            "
            class="item-expand"
          >
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
        <div class="line"></div>
      </v-list>
    </v-menu>
    <div v-else>
      <div class="d-flex flex-column align-center">
        <div class="pointer-path">
          <p
            class="mb-0 pointer-path"
            @click="
              () => {
                handleRouter(path, tag, title);
              }
            "
          >
            {{ title }}
          </p>
        </div>
        <div :class="`d-flex flex-row mt-2 align-center ${classString}`"
        v-if="pathSelect.includes(path)">
          <div v-for="i in pointsColor" :key="i.class" :class="`pointSize ${i.class}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    items: {
      type: Array,
      default: () => [{ title: 'Somos Konecta', path: '/' }],
    },
    title: {
      type: String,
    },
    tag: {
      type: String,
    },
    isSelect: {
      type: Boolean,
    },
    path: {
      type: String,
    },
    classString: {
      type: String,
    },
  },
  data() {
    return {
      pointsColor: [{ class: 'point1' }, { class: 'point2' }, { class: 'point3' }],
      pathSelect: '',
    };
  },
  watch: {
    $route(to) {
      this.pathSelect = to.path;
    },
  },
  methods: {
    trackEvent(tag, value) {
      const eventCategory = `LP_${tag}`;
      const eventLabel = `LP_${tag}`;
      this.$gtag.event(eventCategory, {
        event_category: eventCategory,
        event_label: eventLabel,
        value,
      });
    },

    handleRouter(path, tag, title) {
      const titleValueMap = {
        '¿Quiénes somos?': 1,
        'Konecta empleos': 1,
      };

      const value = titleValueMap[title] || 2;
      this.trackEvent(tag, value);

      this.$router.push(path).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.container-title {
  width: 180px;
}
.line {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 1px;
  border-style: solid;
  border-color: #e1dfdf;
  /* border-color: transparent transparent #000 transparent; */
  width: 100%;
}

.pointer-path {
  cursor: pointer;
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $secondary-darkBlue;
}

.item-expand {
  cursor: pointer;
  font-family: $principal-font !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-black;
}

.point1 {
  background: $secondary-fucsia;
}

.point2 {
  background: $principal-green;
}

.point3 {
  background: $principal-yellow;
}

.pointSize {
  width: 26px;
  height: 6px;
  border-radius: 8px;
  margin: 0px 3px;
}

@media (min-width: 1500px) {
  .container-title {
    width: 214px;
  }
  .pointer-path {
    font-size: 18px;
  }

  .marginPoints1 {
    margin-left: 20%;
  }

  .marginPoints2 {
    margin-left: 15%;
  }
}
</style>
