import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  defaultAssets: {
    font: {
      family: 'Open Sans',
    },
  },
  theme: {
    themes: {
      light: {
        pink: '#CE0F69',
        blue: '#012754',
        black: '#000000',
        white: '#FFFFFF',
        orange: '#FF702E',
        blueDark: '#002855',
      },
    },
  },
});
